import { BreadcrumbRef, DSBreadcrumbs } from "@/components/DSBreadcrumbs"
import { getSnapshots, updateDocument } from "@/google/firestore"
import useAxiosWithIdToken from "@/hooks/useAxiosWithIdToken"
import { useCallListMetadata } from "@/hooks/useCallList"
import { useScripts } from "@/hooks/useScripts"
import { useCompanyUsers } from "@/hooks/useCompanyUsers"
import { setLoadingBackdrop, setSnackbar } from "@/store/commonSlice"
import { AppDispatch, RootState } from "@/store/store"
import { yupResolver } from "@hookform/resolvers/yup"
import { CallOutlined, ContactPhoneOutlined, LocalPhoneOutlined, PersonAddOutlined, ReceiptLongOutlined } from "@mui/icons-material"
import { Alert, Box, Button, Chip, CircularProgress, Container, FormControl, InputLabel,  MenuItem, Paper, Select, SelectChangeEvent, Skeleton, Stack, TextField, Theme, Typography, useTheme } from "@mui/material"
import { useCallback, useEffect, useMemo, useState } from "react"
import { Controller, SubmitHandler, useForm } from "react-hook-form"
import { useSelector } from "react-redux"
import { useNavigate, useParams } from "react-router-dom"
import * as yup from "yup"
import { useDispatch } from "react-redux"
import { DataGrid, GridColDef, GridSortModel, jaJP } from "@mui/x-data-grid"
import { convertToRangeString, parseCallRangeString } from "@/features/CallList/CallListRangeParser"
import RelativeBackdrop from "@/components/RelativeBackdrop"
import CallListItemCursor from "@/features/CallList/CallListItemCursor"
import { updateLocalCallListItems } from "@/store/callSlice"
import formatTimestamp from "@/utils/formatTimestamp"
import CallListTable from "@/features/CallList/CallListTable"
import { CallListItem } from "@/models/CallList"
import { useCallResultLabels } from "@/hooks/useCallResultLabels"
import { useCompanyPhoneNumbers } from "@/hooks/useCompanyPhoneNumbers";
import { Script } from "@/models/Script"

type FormInput = {
  callRangeMax: string,
  callNum: number,
  callAiNum: number
}

const columns: GridColDef[] = [
  { field: "id", headerName: "番号", width: 36 },
  { field: "companyName", headerName: "会社名", width: 200 },
  { field: "result", headerName: "コール結果", width: 120 }
]

const callListItemsPerPage = 100

let callListItemCursor: CallListItemCursor | null = null

const Call = () => {
  const callListMetadata = useCallListMetadata()
  const scripts = useScripts()
  const navigate = useNavigate()
  const { tenantId } = useParams();
  const dispatch = useDispatch<AppDispatch>()

  const callResultLabels = useCallResultLabels()
  const companyPhoneNumbers = useCompanyPhoneNumbers()
  const [callListId, setCallListId] = useState("")
  const [scriptId, setScriptId] = useState("")
  const [processName, setProcessName] = useState("")
  const [phoneNumber, setPhoneNumber] = useState("")
  const [rowSelection, setRowSelection] = useState([])
  const [selectedUsers, setSelectedUsers] = useState<string[]>([])
  const [fetching, setFetching] = useState(false)
  const [rowCount, setRowCount] = useState(0)               // ページネーション用
  const user = useSelector((state: RootState) => state.user)
  const callListItems = useSelector((state: RootState) => Object.values(state.call.callListItems[callListId] || {}))
  const rows = useMemo(() => (callListItems||[]).map((item, k) => ({
    id: k+1,
    companyName: item.companyName,
    result: callResultLabels[item.lastCallResult] || item.lastCallResult
  })), [callListItems])

  const breadcrumbRefs: BreadcrumbRef[] = useMemo(() => ([
    { title: "AIコール" }
  ]), [])
  const axiosWithId = useAxiosWithIdToken()

  //最初の行番号と長さ
  const [callStartPoint, setcallStartPoint ] = useState(0)
  const [callRange, setCallRange] = useState(0)

  // ADMINかSVでないならhomeへ遷移
  useEffect(() => {
    setLoadingBackdrop({ key: "AICallNavigate", state: true })
    if(!user.isSignedIn)
      return
    if(user.role !== "ADMIN" && user.role !== "SV")
      navigate(`/${tenantId}/`)
    setLoadingBackdrop({ key: "AICallNavigate", state: false })
  }, [user.isSignedIn])

  // callListItemsのフェッチ (ページネーション付き)
  useEffect(() => {
    if(!user.companyId || !callListId)
      return

    const fetch = async () => {
      setFetching(true)
      callListItemCursor = new CallListItemCursor({ companyId: user.companyId, callListId, itemsPerPage: callListItemsPerPage })
      setRowCount(await callListItemCursor.totalNum())
      const results = await callListItemCursor.fetchNext(0)
      console.log(results)
      if(results.length) {
        dispatch(updateLocalCallListItems(results))
      }
      setFetching(false)
    }

    fetch()
  }, [callListId])

  // ページネーション
  const handleChangePage = useCallback((e, newPage: number) => {
    if(!callListId)
      return
    if(!callListItemCursor)
      throw new Error("fetcher is not initialized")
    
    const fetch = async () => {
      setFetching(true)

      const results = await callListItemCursor.fetchNext(newPage)
      if(results.length)
        dispatch(updateLocalCallListItems(results))

      setFetching(false)
    }

    fetch()
  }, [callListItemCursor, dispatch])

  const handleChangeSort = useCallback(async (sortModel: GridSortModel) => {
    console.log(sortModel)
    callListItemCursor = new CallListItemCursor({
      companyId: user.companyId,
      callListId,
      itemsPerPage: 20,
      sort: sortModel.length ? {
        field: sortModel[0].field as keyof CallListItem,
        order: sortModel[0].sort
      } : undefined
    })
    
    setFetching(true)
    try {
      const results = await callListItemCursor.fetchNext(0)
      if(results.length)
        dispatch(updateLocalCallListItems(results))
    } catch (e) {
      console.error(e)
    } finally {
      setFetching(false)
    }
  }, [callListId, user.companyId])

  const schema = useMemo(() => yup.object({
    callRangeMax: yup
      .string()
      .nullable()
      .typeError("数値のみ有効です。")
      .required("コール範囲は必須です。")
      .test(
        'range-test',
        "「(開始番号)-(終了番号)」で指定してください。",
        (value) => Boolean(parseCallRangeString(value))
      )
      .test(
        'max-test',
        `${rowCount}以下の値を指定してください。`,
        (value) => {
          const result = parseCallRangeString(value)
          return result && result.max <= rowCount
        }
      ),
    callNum: yup
      .number().typeError("数値のみ有効です。")
      .required("コール数は必須です。"),
    callAiNum: yup
      .number().typeError("数値のみ有効です。")
      .min(1, "1以上の値を指定してください。")
      .max(50, "50以下の値を指定してください。")
      .required("同時稼働AI数は必須です。"),
    // processName: yup
    //   .string()
    //   .required("プロセス名は必須です。")
    //   .max(20, "プロセス名は大文字で10文字以下に設定してください。")
  }), [rowCount, selectedUsers])

  const { handleSubmit, formState: { isValid, errors }, control, reset } = useForm({
    mode: "all",
    criteriaMode: "all",
    defaultValues: {
      callAiNum: 0,
      callNum: 0,
      callRangeMax: "0"
    },
    shouldFocusError: false,
    resolver: yupResolver(schema)
  })

  const [formValues, setFormValues] = useState<FormInput>({
    callRangeMax: "0",
    callNum: 0,
    callAiNum: 0
  });

  const handleFormChange = useCallback(async (key, value) => {
    if (key === 'callRangeMax') {
      const parseResult = parseCallRangeString(value)
      if(!parseResult) {
        setFormValues(prevValues => ({
          ...prevValues,
          [key]: value
        }))
        return
      }
      const { min, max, selection } = parseResult
      setcallStartPoint(min)
      setRowSelection(selection)
      setCallRange(selection.length)
      setFormValues(prevValues => ({
        ...prevValues,
        [key]: value,
        callNum: selection.length
      }))
    } else {
      // コール範囲以外のフォームフィールドの変更の場合
      setFormValues(prevValues => ({
        ...prevValues,
        [key]: value
      }));
    }
  }, [])

  // チェックボックスでコール範囲を変更した場合
  const handleCheckboxChange = useCallback(async (newModel: number[]) => {
    const mergedString = convertToRangeString(newModel.sort((a, b) => a - b))
    setcallStartPoint(Math.min(...newModel))
    setRowSelection(newModel)
    setCallRange(newModel.length)
    setFormValues(prevValues => ({
      ...prevValues,
      callRangeMax: mergedString,
      callNum: newModel.length
    }))
  }, [])

  const forms = useMemo(() => ([
    { key: "callRangeMax", name: `コール範囲 (選択: ${formValues.callNum}件)`, disabled: !callListId },
    { key: "callAiNum", name: "同時稼働AI数", disabled: false },
  ] as const), [callListId, formValues.callNum])

  // 待機ユーザーの選択
  const companyUsers = useCompanyUsers()
  const waitingUsers = useMemo(() => Object.values(companyUsers).filter(user => {
    // user.role === "USER" &&
    return companyUsers[user.uid]?.callState === "NOT_ASSIGNED"
  }), [companyUsers])
  const theme = useTheme();
  const handleUserSelectChange = (event: SelectChangeEvent<string[]>) => {
    const {
      target: { value }
    } = event
    setSelectedUsers(
      typeof value === "string" ? value.split(",") : value
    )
  }
  const getMenuItemStyles = (name: string, selectedUsers: readonly string[], theme: Theme) => {
    return {
      fontWeight:
        selectedUsers.indexOf(name) === -1
          ? theme.typography.fontWeightRegular
          : theme.typography.fontWeightMedium,
    }
  }

  const handleOnValidSubmit: SubmitHandler<FormInput> = async (data) => {
    if(!user.isSignedIn)
      return

    dispatch(setLoadingBackdrop({ key: "AICallCreateProcess", state: true }))
    const json = JSON.stringify({
      callStartPoint,
      callRange,
      processName,
      companyId: user.companyId,
      uids: selectedUsers,
      scriptId,
      callListId,
      callAiNum: data.callAiNum
    })

    // 選択されたユーザーの状態をASSIGNED(プロセス割り当て済み)へ変更
    // assignedPidはバックエンドから設定
    selectedUsers.forEach(uid => {
      updateDocument(`/users/${uid}`, {
        callState: "ASSIGNED"
      })
    })

    setProcessName("")
    setScriptId("")
    setCallListId("")
    setPhoneNumber("")
    reset({
      callAiNum: 0,
      callNum: 0,
      callRangeMax:  "0",
    })
    setSelectedUsers([])
    
    try {
      console.log(json)
      const res = await axiosWithId.post(import.meta.env.VITE_NGROK_URL + "/maincall", json, {
        headers: {
          "Content-Type": "application/json"
        }
      })
      console.log(res.status)
      console.log(res.statusText)
      dispatch(setLoadingBackdrop({ key: "AICallCreateProcess", state: false }))
      dispatch(setSnackbar({ text: "正常にコールプロセスが作成されました。", open: true, severity: "success" }))
    } catch (e) {
      console.error(e)
      dispatch(setLoadingBackdrop({ key: "AICallCreateProcess", state: false }))
    }
  }

  /**
   * アクティブなスクリプトを取得
   * isDisabledがtrueのものは除外
   * @param scripts 
   * @returns 
   */
  const  activeScripts = (scripts: { [docId: string]: Script }): { [docId: string]: Script } => {
    return Object.entries(scripts)
      .filter(([_, script]) => script.isDisabled !== true || script.isDisabled === undefined)
      .reduce((acc, [docId, script]) => {
        acc[docId] = script;
        return acc;
      }, {} as { [docId: string]: Script });
  }


  return (
    <Container maxWidth="lg" sx={{ py: 4 }}>
      <Box sx={{ mb: 2 }}>
        <DSBreadcrumbs breadcrumbRefs={breadcrumbRefs}></DSBreadcrumbs>
      </Box>
      {
        // ロールがフェッチされるまではスケルトンを表示しておく
        (user.role !== "ADMIN" && user.role !== "SV") &&
        <Skeleton height={600} sx={{ p: 4, transform: "none", }}></Skeleton>
      }
      {
        (user.role === "ADMIN" || user.role === "SV") &&
        <Stack direction="row" gap={3} justifyContent="center">
          <form onSubmit={handleSubmit(handleOnValidSubmit)}>
            <Stack alignContent="center" justifyContent="center" flexWrap="wrap" gap={2} maxWidth={500} mx="auto">
              <Paper sx={{ overflow: "hidden", maxWidth: 500 }}>
                <Box display="flex" alignContent="center" bgcolor="#f3f3f3" sx={{ p: 2 }}>
                  <CallOutlined sx={{ mr: 1 }}></CallOutlined>
                  <Typography textAlign="left">
                    コール設定
                  </Typography>
                </Box>

                <Stack alignContent="center" flexWrap="wrap" gap={2} sx={{ p: 3 }}>
                  <FormControl size="small" fullWidth sx={{ mx: "auto" }}>
                    <InputLabel shrink sx={{ bgcolor: "#fff", px: 0.5 }}>プロセス名</InputLabel>
                    <TextField
                      size="small"
                      value={processName}
                      onChange={e => setProcessName(e.target.value)}
                    >
                    </TextField>
                  </FormControl>

                  <FormControl size="small" fullWidth sx={{ mx: "auto" }}>
                    <InputLabel shrink sx={{ bgcolor: "#fff", px: 0.5 }}>
                      <ReceiptLongOutlined sx={{ mb: -0.75, mr: 0.5 }}></ReceiptLongOutlined>
                      スクリプト
                    </InputLabel>
                    <Select
                      size="small"
                      value={scriptId}
                      onChange={e => setScriptId(e.target.value)}
                    >
                      {
                        Object.entries(activeScripts(scripts)).map(([id, script]) => (
                          <MenuItem value={id} key={id}>
                            { script.name }
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>

                  <FormControl size="small" fullWidth sx={{ mx: "auto" }}>
                    <InputLabel shrink sx={{ bgcolor: "#fff", px: 0.5 }}>
                      <ContactPhoneOutlined sx={{ mb: -0.75, mr: 0.5 }}></ContactPhoneOutlined>
                      コールリスト
                    </InputLabel> 
                    <Select
                      size="small"
                      value={callListId}
                      onChange={e => setCallListId(e.target.value)}
                    >
                      {Object.values(callListMetadata).sort((a, b) => b.updatedAt.toMillis() - a.updatedAt.toMillis()).map(metadata => (
                        <MenuItem value={metadata.id} key={metadata.id}>
                          <Typography
                            minWidth={400}
                            maxWidth={400}
                            mr={1}
                            textOverflow="ellipsis"
                            overflow="hidden"
                          >{metadata.callListName}</Typography>
                          <Typography>(更新日: {formatTimestamp(metadata.updatedAt)})</Typography>
                        </MenuItem>
                      ))}
                    </Select>
                  </FormControl>

                  <Box display="flex" gap={2}>{
                    forms.map(form => (
                      <Controller key={form.key} name={form.key} control={control} render={({ field }) => (
                        <TextField
                          {...field}
                          size="small"
                          label={form.name}
                          error={form.key in errors}
                          disabled={form.disabled}
                          helperText={errors[form.key]?.message || ""}
                          value={formValues[form.key]} // ここでフォームの値を表示
                          onChange={(e) => {
                            const newValue = e.target.value;
                            handleFormChange(form.key, newValue);
                            field.onChange(newValue);
                          }}
                        />
                      )} />
                      ))
                    }
                  </Box>

                  <FormControl fullWidth size="small">
                    <InputLabel shrink sx={{ bgcolor: "#fff", px: 0.5 }}>
                      <LocalPhoneOutlined sx={{ mb: -0.75, mr: 0.5 }}></LocalPhoneOutlined>
                      発信番号
                    </InputLabel>
                    <Select
                      size="small"
                      labelId="user"
                      value={phoneNumber}
                      onChange={e => setPhoneNumber(e.target.value)}
                    >
                      {
                        companyPhoneNumbers?.map(phoneNumber => (
                          <MenuItem value={phoneNumber} key={phoneNumber}>
                            {phoneNumber.replace(/^\+81/, "0")}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>

                  {/* <Box display="flex" alignContent="center" mt={4}>
                    <PersonOutlined sx={{ mr: 1 }}></PersonOutlined>
                    <Typography textAlign="left">
                      割り当てユーザー
                    </Typography>
                  </Box> */}
                  <FormControl sx={{ mx: "auto", mt: 2 }} fullWidth size="small">
                    <InputLabel shrink sx={{ bgcolor: "#fff", px: 0.5 }}>
                      <PersonAddOutlined sx={{ mb: -0.75, mr: 0.5 }}></PersonAddOutlined>
                      割り当てユーザー
                    </InputLabel>
                    <Select
                      multiple
                      size="small"
                      labelId="user"
                      value={selectedUsers}
                      onChange={handleUserSelectChange}
                      sx={{ minHeight: 100 }}
                      renderValue={(selected) => (
                        <Box sx={{ display: 'flex', flexWrap: 'wrap', gap: 0.5 }}>
                          {selected.map((value) => (
                            <Chip key={value} label={companyUsers[value]?.name} />
                          ))}
                        </Box>
                      )}
                    >
                      {
                        waitingUsers.map((user) => (
                          <MenuItem
                            key={user.name}
                            value={user.uid}
                            style={getMenuItemStyles(user.name, selectedUsers, theme)}
                          >
                            {user.name}
                          </MenuItem>
                        ))
                      }
                    </Select>
                  </FormControl>
                </Stack>
              </Paper>

              <Button
                size="large"
                sx={{ maxWidth: 300, mt: 4, mx: "auto" }}
                startIcon={<CallOutlined />}
                type="submit"
                variant="contained"
                color="primary"
                disabled={(user.role !== "ADMIN" && user.role !== "SV") ||
                  !processName || !callListId || !scriptId || !phoneNumber || !isValid || !selectedUsers.length}
              >
                コール開始
              </Button>
            </Stack>
          </form>

          <Stack gap={1} maxWidth={380}>
            <Paper sx={{ opacity: callListId ? 1 : 0.4 }}>
              {/* <Typography color="#999" fontSize="0.85rem">
                コールリスト詳細
              </Typography> */}
              <Box sx={{ height: 500, minHeight: 500, position: "relative" }}>
                <CallListTable
                  callListItems={callListItems}
                  rowSelectionModel={rowSelection}
                  onRowSelectionModelChange={(model) => setRowSelection(model)}
                  showCallResults={false}
                  showCallAICallPage={true}
                  editMode={false}
                  checkboxSelection
                  handleChangePage={(paginationModel) => { handleChangePage(null, paginationModel) }}
                  hideCheckbox
                  handleChangeSort={handleChangeSort}
                  rowCount={rowCount}
                  pageSize={100}
                ></CallListTable>

                {/* <DataGrid
                  columns={columns}
                  rows={rows.slice()}
                  initialState={{
                    pagination: {
                      paginationModel: { page: 0, pageSize: callListItemsPerPage }   // MITだと100が最大らしい
                    }
                  }}
                  // hideFooterPagination
                  // hideFooter
                  scrollbarSize={10}
                  rowHeight={36}
                  checkboxSelection
                    // onRowSelectionModelChange={handleCheckboxChange}
                  rowSelectionModel={rowSelection}
                  slots={{
                    noRowsOverlay: () => <Box px={4} width="100%" height="100%" display="flex" justifyContent="center" alignItems="center">
                      (コールリスト未選択)
                    </Box>
                  }}
                  sx={{
                    "& .MuiDataGrid-cellCheckbox": {
                      display: "none"
                    },
                    "& .MuiDataGrid-columnHeader:first-child": {
                      display: "none"
                    },
                    "& .MuiDataGrid-virtualScroller": {
                      overflowX: "hidden",
                      scrollbarColor: "#ddd #f6f6f6"
                    },
                    "& .MuiTablePagination-selectLabel": { display: { xs: "none", md: "none", lg: "none", xl: "none" } },
                    "& .MuiTablePagination-select": { display: { xs: "none", md: "none", lg: "none", xl: "none" } },
                    "& .MuiTablePagination-selectIcon": { display: { xs: "none", md: "none", lg: "none", xl: "none" } }
                  }}
                  localeText={jaJP.components.MuiDataGrid.defaultProps.localeText}
                  onPaginationModelChange={(paginationModel) => { handleChangePage(null, paginationModel.page) }}
                  rowCount={rowCount}
                ></DataGrid> */}
                <RelativeBackdrop open={fetching}>
                  <CircularProgress sx={{ mx: "auto" }}></CircularProgress>
                </RelativeBackdrop>
              </Box>
            </Paper>
          </Stack>
        </Stack>
      }
    </Container>
  )
}

export default Call