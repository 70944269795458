import { useCallResultLabels } from "@/hooks/useCallResultLabels"
import { CallDirection, CallResult, callResults, manualInboundCallResults, ManualOutboundCallResult, manualOutboundCallResults, tossupCallResults } from "@/models/CallHistory"
import { RootState } from "@/store/store"
import { Button, FormControl, InputLabel, MenuItem, Paper, Select, Stack, TextField, Typography } from "@mui/material"
import { DatePicker } from "@mui/x-date-pickers"
import dayjs from "dayjs"
import { useEffect, useMemo, useState } from "react"
import { useSelector } from "react-redux"

const softStyleInputProps = {
  size: "small",
  sx: {
    bgcolor: "#ffffff",
    "& .MuiOutlinedInput-notchedOutline" : {
      border: "none"
    },
    borderRadius: "4px"
  },
} as const

export type Result = {
  callResult: CallResult | ManualOutboundCallResult | "",
  nextCallDate: dayjs.Dayjs | null,
  callMemo: string
}

type Props = {
  onSubmit: (result: Result) => void,
  callDuration: string,
  manualCalling: boolean,
  direction: CallDirection
}

export const CallScreenResultRegister = ({ onSubmit, callDuration, manualCalling = false, direction }: Props) => {
  const callResultLabels = useCallResultLabels()
  const resultOptions = useMemo(() => {
    if(manualCalling && direction === "OUTGOING")
      return manualOutboundCallResults
    if(manualCalling && direction === "INCOMING")
      return manualInboundCallResults
    return tossupCallResults
  }, [manualCalling, direction])
  const [result, setResult] = useState<Result>({
    callResult: "",
    nextCallDate: null,
    callMemo: ""
  })
  const [isValidResult, setIsValidResult] = useState(false)

  useEffect(() => {
    if(!result.callResult) {
      setIsValidResult(false)
      return
    }
    if([
      "TOSSUP_NURTURING_A",
      "TOSSUP_NURTURING_B",
      "TOSSUP_NURTURING_C",
      "MANUAL_OUTBOUND_NURTURING_A",
      "MANUAL_OUTBOUND_NURTURING_B",
      "MANUAL_OUTBOUND_NURTURING_C",
    ].includes(result.callResult) && !result.nextCallDate) {
      setIsValidResult(false)
      return
    }
    // アポ獲得かコールNGでは次のコール日時は設定不可にする
    if([
      "TOSSUP_APPOINTMENT",
      "DENIED",
      "MANUAL_OUTBOUND_APPOINTMENT",
      "MANUAL_OUTBOUND_DENIED"
    ].includes(result.callResult)) {
      setResult({ ...result, nextCallDate: null })
    }
    setIsValidResult(true)
  }, [result])

  return (
    <Paper sx={{ bgcolor: "#9C9C9C", color: "#fff", p: 4, borderRadius: 0, flexGrow: 1 }}>
      <Stack gap={2}>
        <Typography variant="h4">
          通話終了
        </Typography>
        <Typography mb={3}>
          通話時間 { callDuration }
        </Typography>

        <FormControl size="small" fullWidth>
          <InputLabel>コール結果</InputLabel>
          <Select
            {...softStyleInputProps}
            labelId="user"
            value={result.callResult}
            onChange={e => setResult({ ...result, callResult: e.target.value as CallResult })}
          >
            {
              resultOptions.map(result => (
                <MenuItem value={result} key={result}>
                  { callResultLabels[result] }
                </MenuItem>
              ))
            }
          </Select>
        </FormControl>

        <DatePicker
          slotProps={{ textField: softStyleInputProps, field: { clearable: true } }}
          label="次回コール日時"
          format="YYYY-MM-DD"
          value={result.nextCallDate}
          onChange={(e) => setResult({ ...result, nextCallDate: e })}
          minDate={dayjs()}
          disabled={result.callResult === "DENIED" || result.callResult === "TOSSUP_APPOINTMENT"}
        ></DatePicker>

        <TextField
          {...softStyleInputProps}
          label="コールメモ (任意)"
          minRows={5}
          multiline
          size="small"
          value={result.callMemo}
          onChange={(e) => setResult({ ...result, callMemo: e.target.value })}
        ></TextField>

        <Button type="button" disabled={!isValidResult} variant="contained" onClick={() => onSubmit(result)}>
          登録
        </Button>
      </Stack>
    </Paper>
  )
}