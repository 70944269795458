import { getCurrentUserInfo, getIdToken, signInWithEmail } from "@/google/auth";
import { getSnapshot, getCompanyUsers as getCompanyUsersDB, } from "@/google/firestore";
import { User, UserRole } from "@/models/User";
import { PayloadAction, createAsyncThunk, createSlice } from "@reduxjs/toolkit";

type UserState = { 
    loading: boolean,
    error: {
        status: boolean,
        message: string
    },
    isSignedIn: boolean,
    idToken: string,
    companyUsers: {
        [uid: string]: User
    },
    companyUsersFetched: boolean
} & User

const initialState: UserState = {
    loading: false,
    error: {
        status: false,
        message: ""
    },
    assignedCallSid: "",
    isSignedIn: false,
    idToken: "",
    uid: "",
    name: "",
    email: "",
    companyId: "",
    companyName: "",
    role: "USER",
    createdAt: null,
    updatedAt: null,
    companyUsers: {},
    companyUsersFetched: false,

    assignedPid: "",
    assignedCallsNumber: 0,
    callState: "NOT_LOGGED_IN"
}

export const signIn = createAsyncThunk("user/signIn", async ({ email, password, tenantId = null }: { email: string, password: string, tenantId: string | null }) => {
    if (email === "" || password === "") {
        alert("必須入力項目が未入力です");
        return false;
    }

    const result = await signInWithEmail(email, password, tenantId)
    const user = result.user;

    if (user) {
        const uid = user.uid;

        const snapshot = await getSnapshot("users", [uid])
        const data = snapshot.data();

        return data
    }
})

export const getCurrentUser = createAsyncThunk("user/getCurrentUser", async (): Promise<User & { idToken: string }> => {
    const user = await getCurrentUserInfo()
    if (user) {
        const uid = user.uid;
        const snapshot = await getSnapshot("users", [uid])
        const data = snapshot.data() as User;
        const idToken = await getIdToken()
        return {
            ...data,
            idToken
        }
    }
})

export const refreshIdToken = createAsyncThunk("user/refreshIdToekn", async () => {
    return await getIdToken()
})

export const resetUserState = createAsyncThunk("user/resetUserState", () => {
    return {
        uid: "",
        name: "",
        email: "",
        companyId: "",
        companyName: "",
        role: "USER" as UserRole,
        createdAt: "",
        updatedAt: "",
        assignedPid: "",
        assignedCallsNumber: 0,
        assignedTwilioUuid: "",
        callState: "NOT_LOGGED_IN",
        assignedCallSid: ""
    }
})

const userSlice = createSlice({
    name: "user",
    initialState,
    reducers: {
        updateLocalCompanyUsers: (state, action: PayloadAction<{ [uid: string]: User }>) => {
            state.companyUsers = {
                ...state.companyUsers,
                ...action.payload
            }
        },
        initUserSlice: (state, action: PayloadAction) => {
            Object.entries(structuredClone(initialState)).forEach(([key, value]) => {
                state[key] = value
            })
            console.debug("user slice initialized")
        }
    },
    extraReducers: (builder) => {
        builder.addCase(signIn.pending, (state) => {
            state.loading = true;
            state.error.status = false;
            state.error.message = null;
        })
        builder.addCase(signIn.fulfilled, (state, action) => {
            state.loading = false;
            if(!action.payload) {
                state.loading = false;
                state.error.status = true;
                state.error.message = 'user not found';
                return
            }
            state.isSignedIn = true
            state.uid = action.payload.uid
            state.name = action.payload.name
            state.email = action.payload.email
            state.companyId = action.payload.companyId
            state.companyName = action.payload.companyName
            state.role = action.payload.role
        })
        builder.addCase(signIn.rejected, (state) => {
            state.loading = false;
            state.error.status = true;
            state.error.message = 'failed to sign in';
        })

        builder.addCase(getCurrentUser.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(getCurrentUser.fulfilled, (state, action) => {
            state.loading = false;
            if(!action.payload) {
                state.loading = false;
                state.error.status = true;
                state.error.message = 'user not found';
                return
            }
            
            state.isSignedIn = true
            state.uid = action.payload.uid
            state.name = action.payload.name
            state.email = action.payload.email
            state.companyId = action.payload.companyId
            state.companyName = action.payload.companyName
            state.role = action.payload.role
            state.assignedPid = action.payload.assignedPid
            state.assignedCallsNumber = action.payload.assignedCallsNumber
            state.callState = action.payload.callState
            state.idToken = action.payload.idToken
            state.call = action.payload.call
            state.assignedCallSid = action.payload.assignedCallSid;

            state.companyUsers[action.payload.uid] = action.payload
        })

        builder.addCase(refreshIdToken.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(refreshIdToken.fulfilled, (state, action) => {
            state.idToken = action.payload
        })

        builder.addCase(resetUserState.rejected, (state, action) => {
            console.error(action.error.message)
        })
        builder.addCase(resetUserState.fulfilled, (state, action) => {
            state.loading = false;
            if(!action.payload) {
                state.loading = false;
                state.error.status = true;
                state.error.message = 'user not found';
                return
            }
            state.isSignedIn = false
            state.uid = action.payload.uid
            state.name = action.payload.name
            state.email = action.payload.email
            state.companyId = action.payload.companyId
            state.companyName = action.payload.companyName
            state.role = action.payload.role
        })
    }
});

export const { initUserSlice, updateLocalCompanyUsers } = userSlice.actions
export default userSlice.reducer;