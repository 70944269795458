import { Suspense, useCallback, useEffect, useRef, useState } from 'react';
import './default.css'
import { Outlet, useLocation, useNavigate, useParams } from 'react-router-dom'
import AppBar from '@mui/material/AppBar';
import Box from '@mui/material/Box';
import CssBaseline from '@mui/material/CssBaseline';
import IconButton from '@mui/material/IconButton';
import MenuIcon from '@mui/icons-material/Menu';
import Toolbar from '@mui/material/Toolbar';
import teleapoAiLogo from '@/assets/teleapo_ai_logo.png'
import { Alert, Avatar, Button, Container, Divider, Popover, Snackbar, Stack, Typography } from '@mui/material';
import { useSelector } from 'react-redux';
import { AppDispatch, RootState } from '@/store/store';
import { useAuth } from '@/hooks/useAuth';
import LoadingBackdrop from '@/components/LoadingBackdrop';
import { useDispatch } from 'react-redux';
import { setSnackbar } from '@/store/commonSlice';
import { DSDrawer } from '@/features/drawer/Drawer';
import { Call, Device } from '@twilio/voice-sdk';
import { setCall, setCallDevice } from '@/store/callSlice';
import useAxiosWithIdToken from '@/hooks/useAxiosWithIdToken';
import { signOutCurrentUser } from '@/google/auth';
import { updateDocument } from '@/google/firestore';
import callIncomingImage from '@/assets/call_incoming.png'
import silentAudioPath from '@/assets/silent.mp3'
import { NotificationsNoneOutlined } from '@mui/icons-material';

interface Props {
  hideDrawer?: boolean,
  hideAppBar?: boolean,
  disableTossUp?: boolean,
  waitUserFetch?: boolean
}

interface User {
  uid: string;
}

let popupWindow: null | Window = null
let callAudio: null | HTMLAudioElement = null

const Layout = ({ hideDrawer, hideAppBar, disableTossUp, waitUserFetch = true }: Props) => {
  const isSignedIn = useSelector((state: RootState) => state.user.isSignedIn)
  const name = useSelector((state: RootState) => state.user.name)
  const uid = useSelector((state: RootState) => state.user.uid)
  const callState = useSelector((state: RootState) => state.user.callState)
  const loadingBackdrop = useSelector((state: RootState) => Object.values(state.common.loadingBackdrop).reduce((prev, curr) => prev || curr, false))
  const aiCallLoadingBackdrop = useSelector((state: RootState) => state.common.aiCallLoadingBackdrop)
  const snackbar = useSelector((state: RootState) => state.common.snackbar)
  const dispatch = useDispatch<AppDispatch>()
  const navigate = useNavigate()
  const callDevice = useSelector((state: RootState) => state.call.callDevice)
  const axiosWithId = useAxiosWithIdToken()

  // トスアップ通知許可
  const notificationRef = useRef()
  const [anchorEl, setAnchorEl] = useState<HTMLButtonElement | null>(null)
  useEffect(() => {
    setAnchorEl(notificationRef.current)
  }, [notificationRef])

  // drawer
  const [mobileOpen, setMobileOpen] = useState(false);
  const [isClosing, setIsClosing] = useState(false);
  const { tenantId } = useParams();
  const handleSignOutClick = useCallback(() => {
    if(callState === "NOT_ASSIGNED")
      signOutCurrentUser();
  }, [callState])
  const handleDrawerToggle = useCallback(() => {
    if (!isClosing) {
      setMobileOpen(!mobileOpen);
    }
  }, [isClosing, setMobileOpen]);
  const handleDrawerClose = useCallback(() => {
    setIsClosing(true);
    setMobileOpen(false);
  }, [setIsClosing, setMobileOpen]);
  const handleDrawerTransitionEnd = useCallback(() => {
    setIsClosing(false);
  }, [setIsClosing]);


  //タブを削除した場合の処理
  // useEffect(() => {
  //   const handleBeforeUnload = (e: BeforeUnloadEvent) => {
  //     if(callState === "NOT_ASSIGNED"){
  //       signOutCurrentUser();
  //     }else{
  //       e.preventDefault();
  //       e.returnValue = '通話にアサインされている状態です';
  //       return 
  //     }
  //   };

  //   window.addEventListener('beforeunload', handleBeforeUnload);

  //   return () => {
  //     window.removeEventListener('beforeunload', handleBeforeUnload);
  //   };
  // });

  // onAuthChanged のサブスクライブ
  useAuth()

  // トスアップ通知・遷移用のincoming handler
  const incomingHandler = useCallback((call: Call) => {
    console.log(call.parameters)
    dispatch(setCall(call));

    call.accept()
    call.mute(true);

    (new Notification("トスアップされました", {
      body: "クリックして通話画面へ移動",
      icon: callIncomingImage,
      requireInteraction: true,
    })).onclick = () => {window.focus();};

    console.log("move to callscreen (default incomingHandler)")
    navigate(`/${tenantId}/callscreen`)

    // const popupData = { url: `/${tenantId}/tossup-notification`, name: "_blank", features: "width=456,height=300,rel=opener" };
    // window.open(popupData.url, popupData.name, popupData.features)
  }, [uid, tenantId, dispatch, navigate])

  // callStateがCALL_IN_PROGRESSになったら/callscreenへ遷移
  // useEffect(() => {
  //   if(callState !== "CALL_IN_PROGRESS" || location.pathname === `/${tenantId}/callscreen` || !callInstance)
  //     return
    
  //   (new Notification("トスアップされました", {
  //     body: "クリックして通話画面へ移動",
  //     icon: callIncomingImage,
  //     requireInteraction: true,
  //   })).onclick = () => {window.focus();};

  //   console.log("move to callscreen (default incomingHandler)")
  //   navigate(`/${tenantId}/callscreen`)
  // }, [tenantId, callState, location, callInstance])

  // トスアップ通知
  useEffect(() => {
    if(Notification.permission !== "granted")
      Notification.requestPermission();
    if(disableTossUp && callDevice) {
      callDevice.off("incoming", incomingHandler)
      console.log("incoming handler disabled")
    }
    if (!callDevice || disableTossUp)
      return

    console.log("set call device incoming handler")
    callDevice.removeAllListeners("incoming")
    callDevice.on("incoming", incomingHandler)
    callDevice.on("error", (conn) => console.error(conn))
    callDevice.on("cancel", (conn) => console.error(conn))
  }, [disableTossUp, callDevice, incomingHandler])

  // 離席通知
  useEffect(() => {
    if(callState !== "AWAY")
      return

    dispatch(setSnackbar({
      text: "あなたは現在離席中です。再開する場合は「着席」を押してください。",
      severity: "info",
      open: true,
      autoHideDuration: 30000
    }))
  }, [callState])

  // windows close時に強制的にNOT_LOGGED_INに変更
  useEffect(() => {
    window.addEventListener('beforeunload', (e) => {
      updateDocument(`/users/${uid}`, {
        callState: "NOT_LOGGED_IN"
      })
    })
  }, [])

  // callStateがASSIGNEDになったら同じプロセスに割り当てられたユーザー一覧のポップアップを表示
  // useEffect(() => {
  //   if(!user.isSignedIn)
  //     return
  //   if (popupWindow)
  //     return;
    
  //   // ユーザーのcallStateがASSIGNEDになったら割り当てユーザー一覧のウィンドウを出す
  //   if (userState?.callState === "ASSIGNED") {
  //     const popupData = { url: "/assigned-users", name: "_blank", features: "width=456,height=312,rel=opener" };
  //     popupWindow = window.open(popupData.url, popupData.name, popupData.features)
      
  //     // 子ウィンドウが閉じる前にグローバル変数を初期化
  //     // open直後にunloadハンドラを設定するとunloadがすぐに発火してしまうためloadを噛ませる
  //     popupWindow.addEventListener('load', () => {
  //       popupWindow.addEventListener('unload', () => {
  //         popupWindow.close()
  //         popupWindow = null
  //       })
  //     })
  //   }
  // }, [user.isSignedIn, userState?.callState])

  // callDeviceの初期化
  useEffect(() => {
    if (callDevice) return;
    if (!uid) return;

    const deviceInitialize = async (token: string) => {
      const device = new Device(token, {
        // logLevel:1,
        // @ts-expect-error ???
        codecPreferences: ["opus", "pcmu"],
        sounds: {
          incoming: silentAudioPath
        }
      });
      device.register();
      device.on("unregistered", () => {
        console.log("device unregistered")
      })
      dispatch(setCallDevice(device))
      console.log("call device initialized")
    }

    const getToken = async (uid: string) => {
      const url = import.meta.env.VITE_NGROK_URL + "/token/" + uid
      const res = await axiosWithId.get(url,
        {
          headers: {
            'ngrok-skip-browser-warning': 'true'
          },
        }
      )
      const data = await res.data
      return data.token
    }
    getToken(uid).then(token => deviceInitialize(token))
    const urlBeat = import.meta.env.VITE_NGROK_URL + "/heart-beat"
    const sendHeartbeat = async () => {
      try {
        await axiosWithId.post(urlBeat, { uid: uid });
        console.log("送信しました")
      } catch (err) {
        console.error('Heartbeat error:', err);
      }
    };

    // 初回のハートビート送信
    sendHeartbeat();

    // 30秒ごとにハートビートを送信
    const intervalId = setInterval(sendHeartbeat, 30000);

    // クリーンアップ関数
    return () => clearInterval(intervalId);
  }, [uid])

  const handleSnackbarClose = () => {
    dispatch(setSnackbar({ open: false }))
  }; 

  return (
    <>
      {
        (!waitUserFetch) || isSignedIn ? (
          <Box display="flex" pt={hideAppBar ? "0" : "64px"}>
            <CssBaseline />
            {
              hideAppBar ? "" : (
                <AppBar
                  sx={{
                    top: 0,
                    width: '100%',
                    ml: 0,
                    background: '#FFFFFF',
                    zIndex: 300,
                    boxShadow: 'none',
                  }}
                >
                  <Toolbar sx={{ display: "flex", justifyContent: "space-between" }}>
                    <IconButton
                      color="inherit"
                      aria-label="open drawer"
                      edge="start"
                      onClick={handleDrawerToggle}
                      sx={{ mr: 2, display: { sm: 'none' } }}
                    >
                      <MenuIcon />
                    </IconButton>
                    <Box height={32}>
                      <img src={teleapoAiLogo} style={{ maxWidth: "100%", maxHeight: "100%" }}></img>
                    </Box>

                    {
                      isSignedIn && (
                        <>
                          <Stack direction="row" ml="auto" mr={6} gap={1} alignItems="center" ref={notificationRef}>
                            <NotificationsNoneOutlined sx={{ color: "rgba(0, 0, 0, 0.87)", mr: -0.5 }}></NotificationsNoneOutlined>
                            <Typography sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
                              トスアップ通知:
                            </Typography>
                            {
                              Notification.permission === "granted" ? 
                              <Typography sx={{ color: "#4caf50" }}>有効</Typography> : 
                              <Typography sx={{ color: "#ef5350" }}>無効</Typography>
                            }
                          </Stack>
                          <Popover
                            open={Notification.permission !== "granted" && Boolean(anchorEl)}
                            anchorEl={anchorEl} 
                            anchorOrigin={{ vertical: 'bottom', horizontal: 'left' }}
                          >
                            <Stack p={2} gap={2} fontSize="0.85rem">
                              {
                                Notification.permission === "denied" ?
                                <Typography color="error" fontSize="0.85rem">ブラウザ側で通知がブロックされています。</Typography> :
                                <Typography fontSize="0.85rem">トスアップ通知が無効になっています。</Typography>
                              }
                              <Stack direction="row" gap={2} justifyContent="end">
                                <Button onClick={() => setAnchorEl(null)}>閉じる</Button>
                                <Button
                                  variant="contained"
                                  onClick={() => Notification.requestPermission()}
                                  disabled={Notification.permission === "denied"}
                                >有効化</Button>
                              </Stack>
                            </Stack>
                          </Popover>
                        </>
                        
                      )
                    }

                    <Divider ></Divider>
                    
                    <Stack direction="row" gap={2}>
                      <Avatar>
                      </Avatar>
                      <Stack justifyContent="center">
                        <Typography sx={{ color: "rgba(0, 0, 0, 0.87)" }}>
                          {name}
                        </Typography>
                      </Stack>
                    </Stack>
                  </Toolbar>
                </AppBar>
              )
            }
            {
              hideDrawer ? "" : (
                <DSDrawer
                  onClose={handleDrawerClose}
                  onTransitionEnd={handleDrawerTransitionEnd}
                  onSignOutClick={handleSignOutClick}
                  mobileOpen={mobileOpen}
                ></DSDrawer>
              )
            }
            <Suspense fallback={<Container sx={{ p: 4, textAlign: "center" }}><LoadingBackdrop loading></LoadingBackdrop></Container>}>
              <Outlet />
            </Suspense>

            <LoadingBackdrop loading={loadingBackdrop || aiCallLoadingBackdrop}></LoadingBackdrop>
            <Snackbar
              open={snackbar.open}
              onClose={handleSnackbarClose}
              autoHideDuration={snackbar.autoHideDuration}
            >
              <Alert
                onClose={handleSnackbarClose}
                severity={snackbar.severity}
                variant={snackbar.variant ? snackbar.variant : undefined}
                sx={{ width: "100%" }}
              >
                {snackbar.text}
              </Alert>
            </Snackbar>
          </Box>
        ) : (
          <LoadingBackdrop loading={true}></LoadingBackdrop>
        )
      }
    </>
    
  );
}

export default Layout
